

























































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import ToolbarTitle from '@/components/shared/ToolbarTitle.vue';
import { User } from '@/models/UserModels';
import { namespace } from 'vuex-class';
import UserRoleType from '@/enums/user';

const userModule = namespace('UserModule');

@Component({
  components: {
    ToolbarTitle,
  },
})
export default class AddUsersForm extends Vue {

  @userModule.Getter
  public autoCompleteUsers!: User[];

  @userModule.Action
  public searchAutoCompleteUsers!: (params?: string) => Promise<void>;

  @Prop({
    required: true,
  })
  public title!: string;

  @Prop({
    default: () => [],
  })
  public currentUsers!: User[];

  @Prop({
    default: null,
  })
  public allowedAircraft!: string | null;

  @Prop({
    default: () => [],
  })
  public userRoles!: UserRoleType[];

  private isFormValid: boolean = false;
  private selectedUsers: User[] = [];
  private searchUser: string = '';
  private filteredUsers: User[] = [];

  @Emit('editUsers')
  public editUsers(): User[] {
    return this.selectedUsers;
  }

  @Emit('cancel')
  public cancel(): void {
    return;
  }

  @Watch('searchUser')
  private onSearchUser(): void {
    this.fetchData();
  }

  private created(): void {
    this.selectedUsers = this.currentUsers;
    this.fetchData();
  }

  private removeUser(removedUser: User): void {
    this.selectedUsers = this.selectedUsers.filter((user: User) => removedUser.id !== user.id);
  }

  private async fetchData(): Promise<void> {
    await this.searchAutoCompleteUsers(this.searchUser).then(() => {
      this.filteredUsers = this.autoCompleteUsers.filter((user: User) => {
        return UserRoleType.hasAccessForAircraft(this.allowedAircraft!, this.userRolesString, user.roles);
      });
    });
  }

  private get userRolesString(): string[] {
    return this.userRoles.map((userRole: UserRoleType) => (userRole as string));
  }

  private getItemText(user: User): string {
    return user.givenName ? user.givenName : user.email;
  }

}
