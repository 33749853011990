































































import { Component, Prop } from 'vue-property-decorator';
import { CourseTemplate } from '@/models/CoursesModels';
import { namespace } from 'vuex-class';
import ResizableAddItemCard from '@/components/shared/ResizableAddItemCard.vue';
import CourseTemplatesListItem from '@/components/coursesTemplates/template/CourseTemplatesListItem.vue';
import CourseTemplateForm from '@/components/coursesTemplates/template/CourseTemplateForm.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import { SearchType } from '@/enums/globalUI';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import { mixins } from 'vue-class-component';

const searchModule = namespace('SearchModule');
const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    ResizableAddItemCard,
    CourseTemplatesListItem,
    CourseTemplateForm,
    ConfirmationModal,
  },
})
export default class CourseTemplatesList extends mixins(ResponsiveMixin) {

  public isAddPopUpOpen: boolean = false;
  public isEditionPopUpOpen: boolean = false;
  public isDeleteConfirmationPopUpOpen: boolean = false;

  public selectedTemplate: CourseTemplate | null = null;

  @Prop({
    default: false,
  })
  public canEdit!: boolean;

  @coursesTemplateModule.Action
  public createTemplate!: (template: CourseTemplate) => Promise<void>;

  @coursesTemplateModule.Action
  public editTemplate!: (template: CourseTemplate) => Promise<void>;

  @coursesTemplateModule.Action
  public deleteTemplate!: (templateId: string) => Promise<void>;

  @searchModule.Getter
  public searchItems!: CourseTemplate[];

  @searchModule.Action
  public fetchItemsPerPage!: (searchType: SearchType) => Promise<void>;

  public openAddTemplateForm(): void {
    this.isAddPopUpOpen = true;
  }

  public async addTemplateHandler(template: CourseTemplate): Promise<void> {
    await this.createTemplate(template);
    await this.fetchData();
    this.isAddPopUpOpen = false;
  }

  public closeAddTemplateForm(): void {
    this.isAddPopUpOpen = false;
  }

  public openEditPopup(template: CourseTemplate): void {
    this.selectedTemplate = template;
    this.isEditionPopUpOpen = true;
  }

  public closeEditTemplateHandler(): void {
    this.selectedTemplate = null;
    this.isEditionPopUpOpen = false;
  }

  public async editTemplateHandler(template: CourseTemplate): Promise<void> {
    await this.editTemplate(template);
    await this.fetchData();
    this.selectedTemplate = null;
    this.isEditionPopUpOpen = false;
  }

  public openConfirmationPopup(template: CourseTemplate): void {
    this.selectedTemplate = template;
    this.isDeleteConfirmationPopUpOpen = true;
  }

  public deleteConfirmationPopUp(isDeleted: boolean) {
    if (isDeleted) {
      this.deleteTemplate(this.selectedTemplate!.id as string).then(() => {
        this.fetchData();
      });
    }
    this.selectedTemplate = null;
    this.isDeleteConfirmationPopUpOpen = false;
  }

  private fetchData() {
    this.fetchItemsPerPage(SearchType.COURSETEMPLATE);
  }
}
