











































import { Component, Prop, Emit } from 'vue-property-decorator';
import { User } from '@/models/UserModels';
import { namespace } from 'vuex-class';
import { UISnackBar } from '@/models/UIModels';
import ToolbarTitle from '@/components/shared/ToolbarTitle.vue';
import AddUsersForm from '@/components/coursesPlanner/forms/AddUsersForm.vue';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import UserRoleType from '@/enums/user';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import { mixins } from 'vue-class-component';

const globalUI = namespace('GlobalUIModule');

@Component({
  components: {
    ToolbarTitle,
    AddUsersForm,
    ProfileAvatar,
  },
})
export default class UsersList extends mixins(ResponsiveMixin) {

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  @Prop({
    required: true,
  })
  public titleLabel!: string;

  @Prop({
    required: true,
  })
  public addLabel!: string;

  @Prop({
    default: () => [],
  })
  public usersList!: User[];

  @Prop({
    required: true,
  })
  public courseId!: string;


  // Edition Properties
  @Prop({
    default: false,
  })
  public canEdit!: boolean;

  @Prop({
    default: null,
  })
  public allowedAircraft!: string | null;

  @Prop({
    default: () => [],
  })
  public userRoles!: UserRoleType[];

  public currentUserList!: User[];

  public isAddUserFormOpen: boolean = false;

  public onDeleteUser: boolean = false;

  public created() {
    this.currentUserList = this.usersList;
  }

  private get usersIds(): string[] {
    return this.currentUserList.map((user: User) => user.id);
  }

  private openAddUserPopup(): void {
    this.isAddUserFormOpen = true;
  }

  @Emit('listUpdated')
  private async editUsersHandler(users: User[]): Promise<string[]> {
    const userIds: string[] = users.map((user: User) => user.id);
    this.isAddUserFormOpen = false;
    return userIds;
  }

  private cancelHandler(): void {
    this.isAddUserFormOpen = false;
  }
}
