

























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { CourseTemplate } from '@/models/CoursesModels';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import CourseTemplateForm from '@/components/coursesTemplates/template/CourseTemplateForm.vue';
import ElementsCountCard from '@/components/shared/ElementsCountCard.vue';
import ListItemCardTitle from '@/components/shared/ListItemCardTitle.vue';
import AircraftType from '@/enums/user/aircraft';

@Component({
  components: {
    ConfirmationModal,
    CourseTemplateForm,
    ElementsCountCard,
    ListItemCardTitle,
  },
  filters: {
    uppercase: (value: string) => {
      return value.toUpperCase();
    },
  },
})
export default class CourseTemplatesListItem extends Vue {

  @Prop({
    default: false,
  })
  public canEdit!: boolean;

  @Prop({
    required: true,
  })
  public template!: CourseTemplate;

  @Emit('editEmulator')
  public editEmulatorClick(): CourseTemplate {
    return this.template;
  }

  @Emit('deleteEmulator')
  public deleteEmulatorClick(): CourseTemplate {
    return this.template;
  }

  public openTemplatePage(): void {
    this.$router.push('/courses-templates/' + this.template.id);
  }

  public get tags(): string[] {
    return [AircraftType.getAircraftLabelByType(this.template.aircraftModel)];
  }

}
