


































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { CourseTemplate } from '@/models/CoursesModels';
import RuleUtility from '@/utils/rules';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import aircraft from '@/enums/user/aircraft';
import UserRoleType from '@/enums/user';
import { User } from '@/models/UserModels';
import { namespace } from 'vuex-class';
import AircraftType from '@/enums/user/aircraft';
import { mixins } from 'vue-class-component';

const userModule = namespace('UserModule');

@Component({})
export default class CourseTemplateForm extends mixins(ResponsiveMixin) {

  public ruleUtility = RuleUtility;

  public isFormValid: boolean = false;
  public title: string = '';
  public description: string = '';
  public courseTemplatePrefix: string = '';
  public aircraftModel: string = '';

  @userModule.Getter
  public currentUser!: User;

  @Prop()
  public template!: CourseTemplate;

  @Emit('onSave')
  public save(): CourseTemplate {
    if (this.template) {
      return {
        id: this.template.id,
        title: this.title,
        description: this.description,
        courseIdPrefix: this.courseTemplatePrefix,
        aircraftModel: this.aircraftModel,
      };
    } else {
      return {
        title: this.title,
        description: this.description,
        courseIdPrefix: this.courseTemplatePrefix,
        aircraftModel: this.aircraftModel,
        archived: false,
      };
    }
  }

  @Emit('onCancel')
  public onCancelHandler(): void {
    this.reset();
  }

  public created(): void {
    if (this.template) {
      this.title = this.template.title;
      this.description = this.template.description;
      this.courseTemplatePrefix = this.template.courseIdPrefix;
      this.aircraftModel = this.template.aircraftModel!;
    }
  }

  private onSaveClick(): void {
    this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.isFormValid) {
      this.save();
    }
  }

  private get filteredAircraft(): string[] {
    return aircraft.getAircraft().filter((plane: string) => {
      return UserRoleType.hasAccessForAircraft(
        plane,
        [UserRoleType.FLEET_CHIEF],
        this.currentUser.roles,
      );
    });
  }

  private get aircraftItems(): Array<{ value: string, label: string }> {
    return this.filteredAircraft.map((ac: string) => {
      return {
        value: ac,
        label: AircraftType.getAircraftLabelByType(ac),
      };
    });
  }

  private reset(): void {
    (this.$refs.form as HTMLFormElement).reset();
  }

}
