














































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';
import { CourseTemplate } from '@/models/CoursesModels';
import { UIBreadcrumb } from '@/models/UIModels';
import ListItemCardTitle from '@/components/shared/ListItemCardTitle.vue';
import CourseTemplateForm from '@/components/coursesTemplates/template/CourseTemplateForm.vue';
import CourseTemplatesEmulatorsList from '@/components/courses/CourseEmulatorsList.vue';
import UserMixin from '@/mixins/UserMixin.ts';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import { mixins } from 'vue-class-component';

const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    BreadCrumbs,
    ListItemCardTitle,
    CourseTemplateForm,
    CourseTemplatesEmulatorsList,
  },
  filters: {
    uppercase: (value: string) => {
      return value.toUpperCase();
    },
  },
})
export default class CourseTemplateSingleView extends mixins(UserMixin, ResponsiveMixin) {

  public get breadCrumbsItems(): UIBreadcrumb[] {
    return [
      {
        text: this.$t('courseTemplates.title') as string,
        to: '/courses-templates',
        exact: true,
      },
    ];
  }

  public get idTemplate(): string {
    return this.$route.params.id;
  }

  public isEditionPopUpOpen: boolean = false;

  @coursesTemplateModule.Getter
  public currentTemplate!: CourseTemplate | null;

  @coursesTemplateModule.Getter
  public isLoadingTemplates!: boolean;

  @coursesTemplateModule.Mutation
  public setCurrentTemplate!: (template: CourseTemplate | null) => Promise<void>;

  @coursesTemplateModule.Action
  public fetchCourseTemplate!: (id: string) => Promise<void>;

  @coursesTemplateModule.Action
  public editTemplate!: (template: CourseTemplate) => Promise<void>;

  public openEditPopup(): void {
    this.isEditionPopUpOpen = true;
  }

  public closeEditTemplateHandler(): void {
    this.isEditionPopUpOpen = false;
  }

  public async editTemplateHandler(template: CourseTemplate): Promise<void> {
    await this.editTemplate(template);
    await this.fetchCourseTemplate(this.currentTemplate!.id!);
    this.isEditionPopUpOpen = false;
  }

  private created(): void {
    this.fetchCourseTemplate(this.idTemplate);
  }

  private destroyed(): void {
    this.setCurrentTemplate(null);
  }
}
