
























































































































import { Component } from 'vue-property-decorator';
import { UIBreadcrumb } from '@/models/UIModels';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';
import ListItemCardTitle from '@/components/shared/ListItemCardTitle.vue';
import { namespace } from 'vuex-class';
import UserMixin from '@/mixins/UserMixin.ts';
import { Course, CourseInfo, CourseInfoUpdateData } from '@/models/CoursesModels';
import DateStatus from '@/components/shared/DateStatus.vue';
import CourseTemplatesEmulatorsList from '@/components/courses/CourseEmulatorsList.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import UsersList from '@/components/coursesPlanner/lists/UsersList.vue';
import ResponsiveMixin from '@/mixins/ResponsiveMixin.ts';
import UserRoleType from '@/enums/user';
import { capitalize } from '@/utils/labels_utils';
import EvaluationsListView from '@/components/coursesPlanner/evaluation/EvaluationsListView.vue';
import AircraftType from '@/enums/user/aircraft';
import { mixins } from 'vue-class-component';

const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    EvaluationsListView,
    BreadCrumbs,
    ListItemCardTitle,
    DateStatus,
    CourseTemplatesEmulatorsList,
    UsersList,
    ConfirmationModal,
  },
})
export default class CourseSingleView extends mixins(UserMixin, ResponsiveMixin) {

  public userRoleType = UserRoleType;

  @coursesTemplateModule.Getter
  public currentCourseInfo!: CourseInfo | null;

  @coursesTemplateModule.Getter
  public isLoadingTemplates!: boolean;

  @coursesTemplateModule.Action
  public updateCourse!: (course: Course) => Promise<void>;

  @coursesTemplateModule.Action
  public archiveCourse!: (courseId: string) => Promise<void>;

  @coursesTemplateModule.Mutation
  public setCurrentCourseInfo!: (course: CourseInfo | null) => Promise<void>;

  @coursesTemplateModule.Action
  public fetchCoursesInfosById!: (idCourse: string) => Promise<void>;

  @coursesTemplateModule.Action
  public updateCourseInfos!: (courseInfo: CourseInfoUpdateData) => Promise<void>;

  private isDeleteConfirmationPopUpOpen: boolean = false;

  public get idCourse(): string {
    return this.$route.params.id;
  }

  private get breadCrumbsItems(): UIBreadcrumb[] {
    return [
      {
        text: this.$t('coursesPlanner.title') as string,
        to: '/courses-planner',
        exact: true,
      },
    ];
  }

  private get tags(): string[] {
    if (this.currentCourseInfo) {
      return [AircraftType.getAircraftLabelByType(this.currentCourseInfo.aircraftModel)];
    } else {
      return [];
    }
  }

  private created(): void {
    this.fetchCoursesInfosById(this.idCourse);
  }

  private beforeDestroy(): void {
    this.setCurrentCourseInfo(null);
  }

  private async clientListUpdatedHandler(usersIds: string[]): Promise<void> {
    let updateCourseInfo!: CourseInfoUpdateData;
    updateCourseInfo = {
      courseId: this.idCourse,
      updateData: { clientIds: usersIds },
    };
    await this.setCurrentCourseInfo(null);
    await this.updateCourseInfos(updateCourseInfo);
    await this.fetchCoursesInfosById(this.idCourse);
  }

  private async instructorsListUpdatedHandler(usersIds: string[]): Promise<void> {
    let updateCourseInfo!: CourseInfoUpdateData;
    updateCourseInfo = {
      courseId: this.idCourse,
      updateData: { instructorIds: usersIds },
    };
    await this.setCurrentCourseInfo(null);
    await this.updateCourseInfos(updateCourseInfo);
    await this.fetchCoursesInfosById(this.idCourse);
  }

  private openDeleteConfirmationPopup(): void {
    this.isDeleteConfirmationPopUpOpen = true;
  }

  private async deleteConfirmationPopUp(accept: boolean): Promise<void> {
    if (accept) {
      await this.archiveCourse(this.currentCourseInfo!.id!);
      this.isDeleteConfirmationPopUpOpen = false;
      await this.$router.push('/courses-planner');
    } else {
      this.isDeleteConfirmationPopUpOpen = false;
    }
  }

  private getCapitalizedLabel(key: string): string {
    return capitalize(this.$t(key) as string);
  }

}
