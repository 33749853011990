

































import { Component, Vue } from 'vue-property-decorator';
import { CourseInfo } from '@/models/CoursesModels';
import { namespace } from 'vuex-class';
import { UIBreadcrumb } from '@/models/UIModels';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';
import { UserProfile } from '@/models/UserModels';
import UsersCoursesTabInfo from '@/components/coursesPlanner/usersProfile/UsersCoursesTabInfo.vue';
import UserProfileCard from '@/components/coursesPlanner/usersProfile/UserProfileCard.vue';
import Loader from '@/components/shared/Loader.vue';
import CourseClientCard from '@/components/coursesPlanner/usersProfile/CourseClientCard.vue';

const coursesTemplateModule = namespace('CoursesTemplateModule');
const UserModule = namespace('UserModule');

@Component({
  name: 'UsersProfileView',
  components: {
    BreadCrumbs,
    UsersCoursesTabInfo,
    Loader,
    UserProfileCard,
    CourseClientCard,
  },
})
export default class UsersProfileView extends Vue {
  @coursesTemplateModule.Getter
  public currentCourseInfo!: CourseInfo | null;

  @coursesTemplateModule.Action
  public fetchCoursesInfosById!: (idCourse: string) => Promise<void>;

  @UserModule.Getter
  public selectedUserProfile!: UserProfile | null;

  @UserModule.Action
  public fetchUserProfile!: (idUser: string) => Promise<void>;

  public isLoading: boolean = true;

  public created(): void {
    // Populating Course into currentCourseInfo
    this.fetchCoursesInfosById(this.getCourseId());
    // Populating User into selectedUserProfile
    this.fetchUserProfile(this.getUserId());
  }

  public mounted(): void {
    this.isLoading = false;
  }

  public get breadCrumbsItems(): UIBreadcrumb[] {
    return [
      {
        text: this.$t('coursesPlanner.title') as string,
        to: '/courses-planner',
        exact: true,
      },
      {
        text: this.getCourseTitle(),
        to: '/courses-planner/' + this.getCourseId(),
        exact: true,
      },
    ];
  }

  // Getting Course ID from params
  private getCourseId(): string {
    return this.$route.params.id;
  }

  // Getting Course ID from params
  private getCourseTitle(): string {
    return this.currentCourseInfo ? this.currentCourseInfo.title : '';
  }

  // Getting User ID from params
  private getUserId(): string {
    return this.$route.params.userId;
  }

  // Getting The page title.
  private getPageTitle(): string {
    if (this.selectedUserProfile) {
      return `${this.selectedUserProfile!.firstName} ${this.selectedUserProfile!.lastName}`;
    }
    return this.$t('coursesPlanner.usersProfile.courses') as string;
  }
}
