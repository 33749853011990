

































import { Vue, Component, Prop } from 'vue-property-decorator';
import { User, UserProfile } from '@/models/UserModels';
import { namespace } from 'vuex-class';
import ToolbarTitle from '@/components/shared/ToolbarTitle.vue';
import { CourseInfo } from '@/models/CoursesModels';
import moment from 'moment';
import ImageUserProfile from '@/components/shared/ImageUserProfile.vue';

const UserModule = namespace('UserModule');
const CoursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    ToolbarTitle,
    ImageUserProfile,
  },
  filters: {
    uppercase: (value: string) => {
      return value.toUpperCase();
    },
    format: (date: string) => {
      return moment(date).format('YYYY-MM-DD');
    },
  },
})
export default class UserProfileCard extends Vue {
  @UserModule.Getter
  public selectedUser!: User | null;

  @CoursesTemplateModule.Getter
  public coursesClient!: CourseInfo[];

  @CoursesTemplateModule.Getter
  public courseFinishByClient!: CourseInfo[];

  @UserModule.Action
  public fetchSelectedUser!: (idUser: string) => Promise<void>;

  @CoursesTemplateModule.Action
  public fetchCoursesByClient!: (userID: string) => Promise<void>;

  @CoursesTemplateModule.Mutation
  public setCourseFinishByClient!: (courseInfo: CourseInfo[]) => void;

  @Prop({
    required: true,
  })
  public userProfile!: UserProfile;

  public loadingCourseTemplate: boolean = true;

  public created(): void {
    this.fetchSelectedUser(this.getUserId());
    this.getCoursesByClient();
  }

  public async getCoursesByClient(): Promise<void> {
    await this.fetchCoursesByClient(this.getUserId());
    this.setCourseFinishByClient(this.coursesClient);
    this.loadingCourseTemplate = false;
  }

  private get imageURL(): string {
    if (this.userProfile.avatar) {
      return this.userProfile.avatar!;
    } else {
      return '/img/features/anonymous.svg';
    }
  }

  private getUserId(): string {
    return this.$route.params.userId;
  }

  private get username(): string {
    return `${this.userProfile.firstName} ${this.userProfile.lastName}`;
  }

}
