

































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';
import CourseTemplateForm from '@/components/coursesTemplates/template/CourseTemplateForm.vue';
import CourseTemplatesList from '@/components/coursesTemplates/template/CourseTemplatesList.vue';
import UserMixin from '@/mixins/UserMixin.ts';
import PaginationFooter from '@/components/paginationFooter/PaginationFooter.vue';
import { SearchObject } from '@/models/GlobalModels';
import { SearchType } from '@/enums/globalUI';
import Loader from '@/components/shared/Loader.vue';
import { mixins } from 'vue-class-component';

const globalUI = namespace('GlobalUIModule');
const searchModule = namespace('SearchModule');
const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    PaginationFooter,
    BreadCrumbs,
    CourseTemplateForm,
    CourseTemplatesList,
    Loader,
  },
})
export default class CoursesTemplatesLanding extends mixins(UserMixin) {

  @globalUI.Getter
  public isDemoMode!: boolean;

  @searchModule.Getter
  public totalElements!: number;

  @searchModule.Getter
  public totalPages!: number;

  @searchModule.Getter
  public actualPage!: number;

  @searchModule.Getter
  public itemsPerPageOptions!: number;

  @coursesTemplateModule.Getter
  public itemsPerPageCourseTemplate!: number;

  @searchModule.Mutation
  public setActualPage!: (page: number) => void;

  @searchModule.Mutation
  public setItemsPerPage!: (itemsPerPage: number) => void;

  @searchModule.Action
  public fetchItemsPerPage!: (searchType: SearchType) => Promise<void>;

  @searchModule.Action
  public searchItemsReq!: (search: SearchObject) => Promise<void>;

  @coursesTemplateModule.Mutation
  public setItemsPerPageCourseTemplate!: (itemsPerPage: number) => void;

  private isLoading: boolean = false;

  protected created(): void {
    this.isLoading = true;
    this.setItemsPerPage(this.itemsPerPageCourseTemplate);
    this.fetchData();
  }

  private fetchData() {
    this.fetchItemsPerPage(SearchType.COURSETEMPLATE).then(() => {
      this.isLoading = false;
    });
  }

  private updateItemsPerPage(newValue: number | string) {
    let itemsPerPage: number = 0;
    if (newValue !== 'ALL') {
      itemsPerPage = typeof newValue === 'string' ? parseInt(newValue, 10) : newValue;
    } else {
      itemsPerPage = this.totalElements;
    }
    this.setItemsPerPageCourseTemplate(itemsPerPage);
    this.setItemsPerPage(this.itemsPerPageCourseTemplate);
    this.setActualPage(1);
    this.fetchData();
  }

  private nextPage() {
    if (this.actualPage + 1 <= this.totalPages) {
      this.setActualPage(this.actualPage + 1);
      this.fetchData();
    }
  }

  private previousPage() {
    if (this.actualPage - 1 >= 1) {
      this.setActualPage(this.actualPage - 1);
      this.fetchData();
    }
  }
}
